import { percent } from 'src/core/helpers/styles';
import { EDialogGameType } from 'src/core/types/game/EDialogGameType';
import { TDialogGame } from 'src/core/types/game/TDialogGame';

const finalText = 'Попробуйте пройти тренажер еще раз и отработать другие техники.';

export const dialogGamesList: TDialogGame[] = [
  {
    id: EDialogGameType.Default,
    // startVideoUrl: './videos/1c/1c-0-start.mp4',
    defaultScreenId: 'start',
    screens: [
      {
        id: 'final',
        videoUrl: './videos/09.mp4',
        showComment: finalText,
      },
      {
        id: 'start',
        videoUrl: './videos/01.mp4',
        showQuote:
          '*Применим технику открытых вопросов чтобы разговорить пациентку:*\n\n' +
          'Здравствуйте!\n\n' +
          'Я вас поняла… Что послужило поводом для вашего решения?\n\n',
        goTo: 'start_2',
      },
      {
        id: 'start_2',
        videoUrl: './videos/02.mp4',
        showQuote: 'Чем он объясняет свою позицию? Вы с ним разговаривали на эту тему?',
        goTo: 'start_3',
      },
      {
        id: 'start_3',
        videoUrl: './videos/03.mp4',
        answersSx: {
          fontSize: percent(50),
        },
        answers: [
          {
            text: '*Попробую разговорить пациентку с помощью открытого вопроса:*\n\n Как вы сами относитесь к своей беременности?',
            goTo: '1_0',
          },
          {
            text: '*Попробую ободрить и успокоить пациентку:*\n\n Мне это знакомо. Вы не первая и не последняя, кто сталкивается с такой ситуацией. Ваш мужчина поступает неправильно!',
            goTo: '2_0',
          },
          {
            text: '*Буду убеждать пациентку, применю технику разделения проблем:*\n\n Да, молодые люди часто так говорят. И я говорила, потому что боялась и не знала, как одновременно реализовать свои желания и амбиции и воспитывать ребёнка. Оказалось, это возможно… Переживания вашего молодого человека понятны. За что переживаете вы?',
            goTo: '3_0',
          },
        ],
      },
      {
        id: '1_0',
        videoUrl: './videos/04.mp4',
        answersSx: {
          fontSize: percent(50),
        },
        answers: [
          {
            text: '*Применю «отражение чувств»:*\n\n Конечно, вы сейчас в растерянности. Новость о беременности оказалась для вас неожиданной... Что об этой ситуации думают ваши родители?',
            goTo: '1_1',
          },
          {
            text: '*Попробую ободрить и успокоить пациентку:*\n\n Мария Сергеевна, спасибо, что вы пришли на приём и открыто говорите о том, что вас беспокоит. Хочу сказать: «Я, как врач, всегда рядом и готова помочь». Скажите, пожалуйста… что или, может быть, кто ещё поддержит вас в решении сохранить беременность?',
            goTo: '1_2',
          },
          {
            text: '*Применю технику «отражение содержания»:*\n\n Правильно я понимаю, что вы растеряны не потому, что беременны, а из-за того, что беременность наступила неожиданно?',
            goTo: '1_3',
          },
        ],
      },
      {
        id: '1_1',
        videoUrl: './videos/07.mp4',
        answersSx: {
          fontSize: percent(55),
        },
        answers: [
          {
            text: '*Попробую объединиться с пациенткой:*\n\n Мы можем пойти с вами путём, который предлагают ваши родители… Будем рожать?',
            goTo: '1_1_1',
          },
          {
            text: '*Ободрю и успокою пациентку:*\n\n Очень важно, что родители поддерживают ваш выбор и готовы помогать с ребёнком. Я рада за вас.',
            goTo: '1_1_2',
          },
          {
            text: '*Применю технику «отражение содержания»:*\n\n Иными словами, родители на вашей стороне и предлагают сохранить беременность? ',
            goTo: '1_1_3',
          },
        ],
      },
      {
        id: '1_1_1',
        videoUrl: './videos/11.mp4',
        showQuote: 'Вы приняли верное решение!',
        goTo: 'final',
      },
      {
        id: '1_1_2',
        videoUrl: './videos/12.mp4',
        showQuote: 'Спасибо, что вы приняли именно такое решение. У Вас остались какие-то вопросы?',
        goTo: '1_1_2_final',
      },
      {
        id: '1_1_2_final',
        videoUrl: './videos/13.mp4',
        showQuote:
          'Конечно. Вот моя визитка. Всегда готова ответить на ваши вопросы. Всего вам доброго.',
        showComment: finalText,
      },
      {
        id: '1_1_3',
        videoUrl: './videos/14.mp4',
        showQuote: 'Вы приняли верное решение!',
        goTo: 'final',
      },
      {
        id: '1_2',
        videoUrl: './videos/08.mp4',
      },
      {
        id: '1_3',
        videoUrl: './videos/10.mp4',
        answersSx: {
          fontSize: percent(55),
        },
        answers: [
          {
            text: '*Перестроюсь на слушание пациента:*\n\n Да, я вас внимательно слушаю. Продолжайте.',
            goTo: '1_3_1',
          },
          {
            text: '*Попробую ободрить и успокоить пациентку:*\n\n Очень хорошо, что вы задумывались о ребёнке. Значит, вы готовы стать мамой.',
            goTo: '1_3_2',
          },
          {
            text: '*Применю технику «передача инициативы»:*\n\n Знаете, ваши слова «Я думала о ребёнке» привели меня к мысли, что вы уже приняли решение. И это решение – сохранить беременность. Я права?',
            goTo: '1_3_3',
          },
        ],
      },
      {
        id: '1_3_1',
        videoUrl: './videos/15.mp4',
        showQuote: 'Возможно…',
        goTo: '1_3_1_last',
      },
      {
        id: '1_3_1_last',
        videoUrl: './videos/16.mp4',
        showQuote:
          'Да, Мария Сергеевна, я вас поняла. До того, как направить вас на аборт, мы с вами должны сдать анализы. Я выписываю направление… Существующий протокол мне рекомендует также дать вам направление на консультацию психолога. Пусть оно будет у вас. Далее вы сами решите, как им воспользоваться. И после получения результата анализов, мы с вами завершим нашу беседу. Договорились? До свидания.',
        goTo: '1_3_1_final',
      },
      {
        id: '1_3_1_final',
        showComment:
          'Иногда такие ситуации случаются. Важно быть к ним готовым. Попробуйте пройти тренажер еще раз.',
      },
      {
        id: '1_3_2',
        videoUrl: './videos/17.mp4',
        showQuote:
          'Я с радостью поддерживаю ваше решение. Со своей стороны, готова всегда ответить на ваши вопросы и поддержать. Вот моя визитка с номером телефона. Какие вопросы у вас остались?',
        goTo: '1_3_2_last',
      },
      {
        id: '1_3_2_last',
        videoUrl: './videos/18.mp4',
        showQuote:
          'Отлично. Я сейчас выписываю вам направление на анализы. И когда придут результаты, жду вас у себя на приёме. Договорились? Всего вам доброго!',
        goTo: 'final',
      },
      {
        id: '1_3_3',
        videoUrl: './videos/19.mp4',
        showQuote:
          'При моём стаже работы, хочешь - не хочешь станешь психологом. Я рада, что вы скоро станете мамой. Желаю здоровья вам и Вашему малышу.',
        goTo: 'final',
      },
      {
        id: '2_0',
        videoUrl: './videos/05.mp4',
        // The first option for this screen
        showQuestion: 'Как вы думаете, почему пациентка среагировала негативно?',
        answers: [
          {
            text: 'Стоп-фраза',
            goTo: '2_answer',
          },
          {
            text: 'Ошибочная фраза успокаивания',
            goTo: '2_answer',
          },
          {
            text: 'И оценочная стоп-фраза, и ошибочное успокаивание',
            goTo: '2_answer',
          },
        ],
      },
      {
        id: '2_answer',
        showComment:
          'В этом примере две ошибки. Первая – ошибочная фраза успокаивания. Лучше сказать: «Такие ситуации, к сожалению, встречаются». Вторая – стоп-фраза с негативной оценкой. Это обижает пациентку и приводит к конфликту. Попробуйте выбрать другую технику.',
        goTo: '2_continue',
      },
      {
        id: '2_continue',
        showQuestion:
          'Для продолжения беседы выберите вариант, который не содержит стоп-фраз и выправляет ситуацию:',
        showQuestionSx: {
          fontSize: percent(85),
        },
        answersSx: {
          fontSize: percent(55),
        },
        answers: [
          {
            text: 'Я неудачно выразилась. Я не хотела вас задеть, извините. Продолжим?',
            goTo: '2_1',
          },
          {
            text: 'Мария Сергеевна, вы меня неправильно поняли. Я хочу сказать, что ситуация, когда мужчина настаивает на аборте, встречается достаточно часто.',
            goTo: '2_2',
          },
          {
            text: 'Мария Сергеевна, вы должны контролировать своё раздражение, и тогда мы сможем продолжить нашу беседу.',
            goTo: '2_3',
          },
        ],
      },
      {
        id: '2_1',
        showComment:
          'Вы выбрали верный вариант. Но важно произнести эту фразу с правильной интонацией. С какой, разберем на следующем уроке.',
        goTo: '1_0',
      },
      {
        id: '2_2',
        showComment:
          'Высказывание содержит стоп-фразу «Вы меня неправильно поняли». Такая фраза звучит грубо и может обидеть пациентку. Она уверена, что все правильно понимает, и если в разговоре возникает напряжённость, то проблема в самом враче. Попробуйте пройти тренажер еще раз.',
      },
      {
        id: '2_3',
        showComment:
          'Высказывание содержит стоп-фразу «Вы должны». Такая фраза звучит менторски, уводит врача от партнёрской позиции по отношению к пациентке и приводит к конфликту. Попробуйте пройти тренажер еще раз.',
      },
      {
        id: '3_0',
        videoUrl: './videos/06.mp4',
        answersSx: {
          fontSize: percent(50),
        },
        answers: [
          {
            text: '*Применю технику «отражение чувств»:*\n\n Конечно, вы переживаете за отношения с вашим мужчиной. Я уверена, что вам можно помочь. Я готова выписать направление на консультацию психолога. Согласны?',
            goTo: '3_1',
          },
          {
            text: '*Попробую убедить пациентку с помощью замены слов:*\n\n Да, это непростая ситуация... Хочу заметить, что беременность и рождение ребёнка – закономерное развитие отношений двух любящих друг друга людей. Как думаете?',
            goTo: '3_2',
          },
          {
            text: '*Задам открытый вопрос и буду активно слушать пациентку:*\n\n Как протекает ваша беременность?',
            goTo: '3_3_final',
          },
        ],
      },
      {
        id: '3_1',
        videoUrl: './videos/19.mp4',
        showQuote:
          'Договорились. Вот вам направление к психологу. И после консультации с ним жду вас ко мне на приём. До свидания.',
        goTo: 'final',
      },
      {
        id: '3_2',
        videoUrl: './videos/20.mp4',
        showQuote:
          'Мария Сергеевна, я дам вам направление к психологу. Предлагаю с ним побеседовать. Думаю, беседа со специалистом поможет в вашем решении.',
        goTo: '3_2_final',
      },
      {
        id: '3_2_final',
        videoUrl: './videos/22.mp4',
        showQuote:
          'Я уважаю ваше решение. Пожалуйста, оставьте направление психолога у себя. И я вам даю направление на анализы. Когда придут результаты, жду вас к себе на приём. Договорились? До свидания.',
        showComment:
          'Иногда такие ситуации случаются. Важно быть к ним готовым. Попробуйте пройти тренажер еще раз.',
        showQuoteSx: {
          fontSize: percent(75),
        },
      },
      {
        id: '3_3_final',
        videoUrl: './videos/23.mp4',
        showComment:
          'Врач прерывает пациентку вопросом вне контекста беседы. У неё складывается впечатление, что он не внимателен к ней. Это подрывает доверие.\n' +
          'Правильно сначала подтвердить переживания пациентки, затем задать необходимый вопрос.\n' +
          'Попробуйте пройти тренажер еще раз.',
      },
    ],
  },
];

export const dialogGamesHash = dialogGamesList.reduce(
  (hash, game) => {
    hash[game.id] = game;
    return hash;
  },
  {} as Record<EDialogGameType, TDialogGame>,
);
